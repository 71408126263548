const settings = {
    speed: 500,
    slidesToShow: 4,
    dots: false,
    swipeToSlide: true,
    responsive: [
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 680,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 475,
            settings: {
                slidesToShow: 1,
            }
        }

    ]
};

export default settings