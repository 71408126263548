import React from "react";
import { VitrineItem } from "./VitrineElements"

const VitrineItems = (items, handleClick) => {

    const showModal = (item) => {
        handleClick(item);
        
    }


    const vitrineItens = items.map((item) => {
        return (
            <VitrineItem img={item.foto} onClick={(e) => {showModal(item)}}/>
        );
  });

    return vitrineItens;
}

export default VitrineItems;