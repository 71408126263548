import styled from "styled-components"
import { DefaultWrapper } from "../Clientes/ClientesElements";
import backgroundElements from "../../assets/svg/bgElement.svg";
import backgroundElement7 from "../../assets/svg/bgElement7.svg";

export const DefaultVitrineWrapper = styled(DefaultWrapper)`
    background-image: url(${backgroundElements});
    background-position: left;
    background-repeat: no-repeat;
`

export const VitrineScreen = styled.div`
   margin-left: auto;
    margin-right: auto;
    display: block;
    max-width: 1100px;
`;

export const VitrineContainer = styled.div`
    margin-top: 10px;
    display:grid;
    justify-content: center;
    grid-gap: 10px;
    grid-template-columns: repeat( auto-fill, minmax(250px, 350px)  );
    grid-auto-rows: auto;
`;

export const VitrineItem = styled.div`
    display: flex;
    cursor: pointer;
    height: 221px;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    background-image: url(${props => props.img});
`;

export const ShowDetailsWrapper = styled.div`
    display: ${({ isOpen }) => (isOpen ? 'flex' : "none")};
    opacity: ${({ isOpen }) => (isOpen ? '1' : "0")};
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0; 
    width: 100%;    
    height: 100%;
    background-color: RGBA(0,0,0,0.5);

    z-index: 1000;
    transition: 0.5s opacity;
`;

export const ShowDetailsContainer = styled.div`
    position: absolute;
    z-index: 1001;
    max-width: 1000px;
    max-height: 100%;
    transition: 0.3s top;
    left: 0; right: 0; bottom: 0; 
    margin: 0 auto;
    border-radius: 20px 20px 0 0;
    background-color: white;
    padding: 0 15px 10px 10px;
    overflow: auto;
    animation: animateFromBottom 0.5s cubic-bezier(.93,.11,.05,1.02);
    background-image: url(${backgroundElement7});
    background-position: right;
    background-repeat: no-repeat;

    @keyframes animateFromBottom {
        from{
            transform: translateY(100%);
            opacity: 0;
        }
        to{
            transform: translateY(0);
            opacity: 1;
        }
    }
`;

export const ShowDetailsText = styled.p`
    font-size: 3.5rem;
    font-family: 'prelo-book';
    line-height: 4rem;
    padding: 20px;
    text-indent: 30px;
    text-align: justify;
    text-justify: inter-word;
`;

export const ShowDetailsImageContainer = styled.div`
    width:100%;
    margin: 0 auto;
`

export const ShowDetailsImage = styled.img`
    float: left;
    margin: 0 20px;
    height: auto;
    width: 50%;

    @media (max-width: 400px){
        width: 90%;
    }
`;


export const CloseButtonContainer = styled.div`
    margin-bottom: 10px;
`;

export const CloseButton = styled.div`
    color: var(--main-color);
    cursor: pointer;
    font-size: 3.5rem;
`;