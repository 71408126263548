import React, { useState, useRef, useEffect } from 'react'
import { ClientesTitulo, ClientesSubtitle } from "../Clientes/ClientesElements"
import ScrollAnimation from 'react-animate-on-scroll'
import { DefaultVitrineWrapper, VitrineContainer, VitrineScreen, ShowDetailsWrapper, ShowDetailsImageContainer, ShowDetailsContainer, ShowDetailsText, ShowDetailsImage, CloseButtonContainer, CloseButton } from "./VitrineElements.js"
import api from "../../servives/api"
import vitrineItems from "./VitrineItens"
import { MdKeyboardBackspace } from "react-icons/md";


const Vitrine = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [vitrine, setVitrine] = useState([]);
    const [selectedItem, setSelectedItem] = useState([]);

    useEffect(() => {
        api
            .get("/vitrine")
            .then((response) => {
                setVitrine(response.data);
                selectedItem(vitrine[0]);
            })
            .catch((err) => {
            });
    }, [selectedItem, setVitrine])

    const handleClick = (item) => {
        console.log(item);
        setIsOpen(!isOpen);
        setSelectedItem(item);
    }


    return (
        <DefaultVitrineWrapper id="vitrine">
            <VitrineScreen>

                <ClientesTitulo>
                    Vitrine
                </ClientesTitulo>
                <ClientesSubtitle>
                    Projetos
                </ClientesSubtitle>

                <ScrollAnimation animateIn="animate__fadeIn" duration={0.5} offset={300} animateOnce={true}>
                    <VitrineContainer>
                        {vitrine.length > 0 ? vitrineItems(vitrine, handleClick) : []}
                    </VitrineContainer>
                </ScrollAnimation>
            </VitrineScreen>
            <ShowDetailsWrapper isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
                <ShowDetailsContainer isOpen={isOpen}>
                    {/* <CloseButtonContainer>
                        <CloseButton>
                            <MdKeyboardBackspace onClick={() => setIsOpen(!isOpen)} />
                        </CloseButton>
                    </CloseButtonContainer> */}
                    <ShowDetailsImageContainer>
                        <ShowDetailsImage src={selectedItem.foto} />
                    </ShowDetailsImageContainer>
                    <ShowDetailsText>
                        {selectedItem.descricao}
                    </ShowDetailsText>
                </ShowDetailsContainer>
            </ShowDetailsWrapper>
        </DefaultVitrineWrapper>


    )
}

export default Vitrine
