import React from "react"
import { ImageCircle, ImageContainer, Image } from "./ClientesElements"
import nubank from "../../assets/img/nubank.png"
const CreateClientesCircle = (items) => {

    const positions = [
        {
            height: "30%",
            width: "30%",
        },
        {
            height: "25%",
            width: "25%",
            transform: "translate(calc(100% * 3))"
        }, {

            height: "40%",
            width: "40%",
            transform: "translate(75%, 50%)"
        }, {
            height: "25%",
            width: "25%",
            transform: "translate(-20%, 150%)"
        }, {
            height: "30%",
            width: "30%",
            transform: "translate(200%, 200%)"
        }, {
            height: "25%",
            width: "25%",
            transform: "translate(50%, 250%)"
        },
        {
            height: "25%",
            width: "25%",
            transform: "translate(150%, -50%)"
        }
    ];


    const clientesCircle = [];


    const lenght = items.length > 6 ? 6 : items.lenght;


    for (let i = 0; i < 7; i++) {
        clientesCircle.push(
            <ImageCircle key={i} style={positions[i]}>
                <ImageContainer >
                    <Image src={items[i].foto} />
                </ImageContainer>
            </ImageCircle>
        )
    }
    console.log(clientesCircle)

    return clientesCircle
}

export default CreateClientesCircle;