import React from 'react'
import Typical from 'react-typical'



import { HomeContainer, HomeContent, HomeSubtitle, HomeTitle, HomeButton, ButtonText } from "./HomeElements.js"

const Home = () => {
    return (
        <HomeContainer id="home">
            <HomeContent>
                <HomeSubtitle>
                    INOVA SOROCABA
                </HomeSubtitle>
                <HomeTitle>
                    CENTRO DE DESENVOLVIMENTO
                    <p>
                        DE {' '}
                        <Typical
                            steps={['APLICATIVOS', 1000, 'JOGOS', 1000, 'SOFTWARES', 1000, 'SISTEMAS', 1000]}
                            loop={Infinity}
                            wrapper="b"
                        />
                    </p>
                    {/* <HomeButton>
                        <ButtonText>
                            FALE CONOSCO
                        </ButtonText>
                    </HomeButton> */}
                </HomeTitle>
            </HomeContent>
        </HomeContainer>
    )
}

export default Home
