import React, { useEffect } from 'react'
import {
    Nav,
    NavContainer,
    NavLogo,
    NavMenu,
    NavLinks,
    MobileIcon,
} from './NavBarElements.js'
import SvgComponent from '../../assets/svg/cda.js'
import { useState } from 'react';
import { FaBars } from "react-icons/fa"
import { animateScroll as scroll } from "react-scroll";
export const NavBar = () => {
    const [isOpen, setIsOpen] = useState(true);
    const scrollToTop = () => {
        scroll.scrollToTop();
    };

    const [background, setBackground] = useState(false)
    const [topPosition, setTopPosition] = useState(true)

    let prev = window.scrollY;
    const scrollListener = () => {
        if (window.scrollY > 10) {
            setBackground(true)
        } else {
            setBackground(false);
        }
        window.scrollY < prev ?
            setTopPosition(true)
            :
            setTopPosition(false)
        prev = window.scrollY;
    };


    useEffect(() => {
        window.addEventListener('scroll', scrollListener);
        isOpen && (document.body.style.overflow = 'unset');
        !isOpen && (document.body.style.overflow = 'hidden');
        !isOpen && (setBackground(true));
        isOpen && (setBackground(false));
        return () => {
            window.removeEventListener('scroll', scrollListener)
        }
    }, [isOpen]);

    return (
        <Nav background={background} topPosition={topPosition}>
            <NavContainer >
                <NavLogo
                    onClick={scrollToTop}
                    to="/"
                    style={{ textDecoration: "none" }}
                >
                    <SvgComponent fill={background ? "#6BA530" : "#fff"} />
                </NavLogo>
                <NavMenu isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} >
                    <NavLinks
                        to="home"
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        offset={-50}
                        duration={500}
                        onClick={() => setIsOpen(!isOpen)}
                        style={{ textDecoration: "none" }}>
                        Home
                    </NavLinks>
                    <NavLinks

                        to="vitrine"
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        offset={-50}
                        duration={500}
                        onClick={() => setIsOpen(!isOpen)}
                        style={{ textDecoration: "none" }}

                    >
                        Vitrine
                    </NavLinks>
                    <NavLinks

                        to="clientes"
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        offset={-50}
                        duration={500}
                        onClick={() => setIsOpen(!isOpen)}
                        style={{ textDecoration: "none" }}

                    >
                        Clientes
                    </NavLinks>
                    <NavLinks
                        to="noticias"
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        offset={-50}
                        duration={500}
                        onClick={() => setIsOpen(!isOpen)}
                        style={{ textDecoration: "none" }}
                    >
                        Notícias
                    </NavLinks>
                    <NavLinks
                        to="equipe"
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                        onClick={() => setIsOpen(!isOpen)}
                        style={{ textDecoration: "none" }}>
                        Colaboradores
                    </NavLinks>
                </NavMenu>
                <MobileIcon onClick={() => setIsOpen(!isOpen)}>
                    <FaBars />
                </MobileIcon>
            </NavContainer>
        </Nav>
    );
}





