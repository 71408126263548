import React, { useState, useEffect } from 'react'
import {
    CardContainer,
    CardFront,
    CardContentWrapper,
    ProfileImageContainer,
    ProfileImage,
    ProfileNameWrapper,
    ProfileName,
    ProfileJob,
    CardBack,
    IconAndLevelWrapper,
    Icon,
    ProgressBar,
    ProgressLevel,
    LinkedInIconWrapper,
    LinkedInIcon,
    Wrapper,
    EquipeDivider,
    EquipeWrapper,
    CarouselWrapper,
    EquipeTitle,
    EquipeSubtitle,
    EquipeDividerFlipped,
} from "./EquipeElements.js"
import ScrollAnimation from 'react-animate-on-scroll'
import "animate.css/animate.min.css";
import api from "../../servives/api.js"
import CarouselData from "./CarouselData"


function Equipe() {

    const [membros, setMembros] = useState([]);
    useEffect(() => {
        api
            .get("/equipe")
            .then((response) => setMembros(response.data))
            .catch((err) => {
            });
    }, []);

    const createElements = (membros) => {
        const elements = membros.map((membro) => {
            return <CardContainer >
                <CardFront>
                    <CardContentWrapper>
                        <ProfileImageContainer>
                            <ProfileImage src={membro.foto} />
                        </ProfileImageContainer>
                        <ProfileNameWrapper>
                            <ProfileName>
                                {membro.nome}
                            </ProfileName>
                        </ProfileNameWrapper>
                        <ProfileJob>
                            {membro.bio}
                        </ProfileJob>
                    </CardContentWrapper>
                </CardFront>
                <CardBack>
                    <CardContentWrapper>
                        <IconAndLevelWrapper>
                            <Icon viewBox="0 0 34 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17 0.16C7.84921 0.16 0.679993 4.04344 0.679993 9C0.679993 13.9566 7.84921 17.84 17 17.84C26.1508 17.84 33.32 13.9566 33.32 9C33.32 4.04344 26.1508 0.16 17 0.16ZM15.3053 2.88H17.0877L16.5219 5.6H18.113C19.1223 5.6 19.7997 5.74078 20.1848 6.09406C20.562 6.44203 20.6762 7.01312 20.5248 7.79141L19.8183 11.04H18.0067L18.6575 8.03578C18.7372 7.62406 18.7053 7.33719 18.5672 7.18578C18.4291 7.03438 18.1262 6.96 17.672 6.96H16.2456L15.385 11.04H13.6L15.3053 2.88ZM7.47999 5.6H11.1058C12.835 5.6 13.8816 6.75812 13.4725 8.48734C12.997 10.4955 11.722 11.04 9.37921 11.04H8.25827L7.90234 13.08H6.1014L7.47999 5.6ZM21.76 5.6H25.3858C27.115 5.6 28.1616 6.75812 27.7525 8.48734C27.277 10.4955 26.002 11.04 23.6592 11.04H22.5383L22.1823 13.08H20.3814L21.76 5.6ZM9.02327 6.96L8.51062 9.68H9.67406C10.6808 9.68 11.6158 9.56578 11.7725 8.065C11.8309 7.48328 11.5892 6.96 10.4258 6.96H9.02327ZM23.3033 6.96L22.7906 9.68H23.9541C24.9608 9.68 25.8958 9.56578 26.0525 8.065C26.1109 7.48328 25.8692 6.96 24.7058 6.96H23.3033Z" fill="white" />
                            </Icon>
                            <ProgressBar>
                                <ProgressLevel level={membro.habilidade1 + "%"} />
                            </ProgressBar>
                            <Icon viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.5 0C10.1426 0 9.78516 0.089844 9.4668 0.269531L1.04492 5.00195C0.400391 5.36328 0 6.04102 0 6.76758V16.2324C0 16.959 0.400391 17.6367 1.04492 17.998L9.4668 22.7305C9.78516 22.9102 10.1426 23 10.5 23C10.8574 23 11.2148 22.9102 11.5332 22.7305L19.9551 18C20.5996 17.6367 21 16.959 21 16.2324V6.76758C21 6.04102 20.5996 5.36328 19.9551 5.00195L11.5332 0.269531C11.2148 0.089844 10.8574 0 10.5 0ZM10.5 5.5C12.3906 5.5 14.1387 6.37695 15.2715 7.86914L13.0801 9.13867C12.4219 8.41797 11.4863 8 10.5 8C8.57031 8 7 9.57031 7 11.5C7 13.4297 8.57031 15 10.5 15C11.4863 15 12.4219 14.582 13.0801 13.8613L15.2715 15.1309C14.1387 16.623 12.3906 17.5 10.5 17.5C7.19141 17.5 4.5 14.8086 4.5 11.5C4.5 8.19141 7.19141 5.5 10.5 5.5ZM15.5 9H16.5V10H17.5V9H18.5V10H19.5V11H18.5V12H19.5V13H18.5V14H17.5V13H16.5V14H15.5V13H14.5V12H15.5V11H14.5V10H15.5V9ZM16.5 11V12H17.5V11H16.5Z" fill="white" />
                            </Icon>
                            <ProgressBar>
                                <ProgressLevel level={membro.habilidade2 + "%"} />
                            </ProgressBar>
                            <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21.7316 1.116C21.6404 1.01664 21.5122 0.959999 21.3773 0.959999H2.62276C2.48788 0.959999 2.35972 1.01664 2.26852 1.116C2.17732 1.21536 2.13268 1.3488 2.14468 1.48272L3.83428 20.4077C3.85204 20.6054 3.9898 20.772 4.18084 20.8267L11.8656 23.0218C11.9084 23.0338 11.953 23.04 11.9972 23.04C12.0413 23.04 12.086 23.0338 12.1287 23.0218L19.8202 20.8267C20.0112 20.772 20.149 20.6054 20.1668 20.4077L21.8554 1.48272C21.8674 1.3488 21.8223 1.21536 21.7316 1.116ZM5.76004 13.9219L9.12004 14.8541V5.28H11.04V17.76L5.76004 16.2955V13.9219ZM18.266 10.56L17.76 16.44L12.96 17.76V15.5515L16.2 14.6611L16.32 12.8611L12.96 13.44V5.28H18.72L18.5544 7.2H14.88V11.04L18.266 10.56Z" fill="white" />
                            </Icon>
                            <ProgressBar>
                                <ProgressLevel level={membro.habilidade3 + "%"} />
                            </ProgressBar>
                        </IconAndLevelWrapper>
                        <LinkedInIconWrapper>
                            <LinkedInIcon viewBox="0 0 52 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M41.1667 0H10.8333C4.85117 0 0 4.38471 0 9.79167V37.2083C0 42.6153 4.85117 47 10.8333 47H41.1667C47.151 47 52 42.6153 52 37.2083V9.79167C52 4.38471 47.151 0 41.1667 0ZM17.3333 37.2083H10.8333V15.6667H17.3333V37.2083ZM14.0833 13.1835C11.9903 13.1835 10.2917 11.6364 10.2917 9.729C10.2917 7.82158 11.9903 6.2745 14.0833 6.2745C16.1763 6.2745 17.875 7.82158 17.875 9.729C17.875 11.6364 16.1785 13.1835 14.0833 13.1835ZM43.3333 37.2083H36.8333V26.2338C36.8333 19.6382 28.1667 20.1375 28.1667 26.2338V37.2083H21.6667V15.6667H28.1667V19.1231C31.1913 14.0589 43.3333 13.6848 43.3333 23.972V37.2083Z" fill="white" />
                            </LinkedInIcon>
                        </LinkedInIconWrapper>
                    </CardContentWrapper>
                </CardBack>
            </CardContainer>
        });

        return elements;
    }

    return (
        <div>
            <Wrapper id="equipe">
                <EquipeDivider />
                <EquipeWrapper>
                    <EquipeTitle>
                         equipe
                    </EquipeTitle>
                    <EquipeSubtitle>
                        Quem faz acontecer
                    </EquipeSubtitle>
                    {/* <ScrollAnimation animateIn="animate__fadeInDown" duration={0.5} offset={200} animateOnce={true}> */}
                    <CarouselWrapper>
                        <CarouselData membros={membros ? createElements(membros) : []} />
                    </CarouselWrapper>
                    {/* </ScrollAnimation> */}
                </EquipeWrapper>
                <EquipeDividerFlipped />
            </Wrapper>
        </div>
    )
}

export default Equipe