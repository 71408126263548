import React from 'react'
import settings from "./CarouselSettings"
import Carousel from "../Carousel/Carousel";

function CarouselData({membros}){

    
    return(
        <Carousel
            propSettings={settings}
        >
            {membros.map((membro)=>{return membro})}
        </Carousel>
    );
}

export default CarouselData;