import React, { Fragment } from 'react'
import SvgComponent1 from "../../assets/svg/cda.js"
import SvgComponent from "../../assets/svg/parque.js"
import { AiOutlinePhone } from "react-icons/ai"
import { AiOutlineMail } from "react-icons/ai"
import {
    FooterWrapper,
    FooterContainer,
    LogoContainer,
    LogoWrapper,
    MidiaAndContactContainer,
    ContactContainer,
    ContactText,
    LinksContainer,
    Links,
    FooterRights
} from "./FooterElements.js"


const Footer = () => {


    return (
        <div>
            <FooterWrapper>
                <FooterContainer>
                    <LogoContainer>
                        <LogoWrapper>
                            <SvgComponent1 fill={'#fff'} />
                        </LogoWrapper>
                        <LogoWrapper>
                            <SvgComponent />
                        </LogoWrapper>
                    </LogoContainer>
                    <LinksContainer>
                        <Links
                            to="home"
                            activeClass="active"
                            spy={true}
                            smooth={true}
                            offset={-50}
                            duration={500}
                            style={{ textDecoration: "none" }}>
                            Home
                        </Links>
                        <Links

                            to="vitrine"
                            activeClass="active"
                            spy={true}
                            smooth={true}
                            offset={-50}
                            duration={500}
                            style={{ textDecoration: "none" }}

                        >
                            Vitrine
                        </Links>
                        <Links

                            to="clientes"
                            activeClass="active"
                            spy={true}
                            smooth={true}
                            offset={-50}
                            duration={500}
                            style={{ textDecoration: "none" }}

                        >
                            Clientes
                        </Links>
                        <Links
                            to="noticias"
                            activeClass="active"
                            spy={true}
                            smooth={true}
                            offset={-50}
                            duration={500}
                            style={{ textDecoration: "none" }}
                        >
                            Notícias
                        </Links>
                        <Links
                            to="equipe"
                            activeClass="active"
                            spy={true}
                            smooth={true}
                            offset={-100}
                            duration={500}
                            style={{ textDecoration: "none" }}>
                            Colaboradores
                        </Links>
                    </LinksContainer>
                    <MidiaAndContactContainer>
                        <ContactContainer>
                            <AiOutlinePhone />
                            <ContactText>
                                15 99144-5981
                            </ContactText>
                        </ContactContainer>
                        <ContactContainer>
                            <AiOutlineMail />
                            <ContactText>
                                suporte@inovasorocaba.org.br
                            </ContactText>
                        </ContactContainer>
                    </MidiaAndContactContainer>

                </FooterContainer>
            </FooterWrapper>
            <FooterRights>
                © 2021  Desenvolvimento e Inovação de Sorocaba - Inova Sorocaba. Todos direitos reservados
            </FooterRights>
        </div >
    );
}

export default Footer;