import React, { useState, useEffect } from 'react'
import clientesCircle from "./ClientesContainer"
import ScrollAnimation from 'react-animate-on-scroll'
import "animate.css/animate.min.css";
import api from "../../servives/api"
import { ClientesContainer, ClientesTitulo, ClientesImages, ClientesSubtitle, ClientesTexto, ClientesWrapper, Image, ImagesWrapper, Title, Subtitle, ImageContainer, DefaultClientesWrapper } from "./ClientesElements.js"
const Clientes = () => {
    const [clientes, setClientes] = useState([]);


    useEffect(() => {
        api
            .get("/clientes")
            .then((response) => setClientes(response.data))
            .catch((err) => {
            });
    }, [])


    return (
        <DefaultClientesWrapper id="clientes">
            <ClientesWrapper>
                    <ClientesTitulo>
                        Clientes
                    </ClientesTitulo>
                    <ClientesSubtitle>
                    Últimos clientes
                </ClientesSubtitle>
                <ClientesContainer>
                    <ClientesImages>
                        <ImagesWrapper>
                            {clientes.length > 0 ? clientesCircle(clientes) : []}
                        </ImagesWrapper>
                    </ClientesImages >
                </ClientesContainer>
            </ClientesWrapper>
        </DefaultClientesWrapper>
    )
}

export default Clientes
