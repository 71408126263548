import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BiChevronRight } from "react-icons/bi";
import { BiChevronLeft } from "react-icons/bi"



function Carousel({ propSettings, children, backgroundColor }) {


    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <BiChevronRight className={className}
                style={{ ...style, display: "block", height: "3.5rem", width: "3.5rem", borderRadius: "50%", color: "white", backgroundColor: backgroundColor, }}
                onClick={onClick} />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <BiChevronLeft
                className={className}
                style={{ ...style, display: "block", height: "3.5rem", width: "3.5rem", borderRadius: "50%", color: "white", backgroundColor: backgroundColor }}
                onClick={onClick}
            />
        );
    }

    const settings = { ...propSettings, nextArrow: <SampleNextArrow />, prevArrow: <SamplePrevArrow /> }

    return (
        <Slider {...settings}>
            {React.Children.map(children, (child, i) => {
                return <React.Fragment>{child}</React.Fragment>;
            })}
        </Slider>
    );
}


export default Carousel;