import styled from "styled-components"
import backgroundElements2 from "../../assets/svg/bgElement2.svg";
import backgroundElements3 from "../../assets/svg/bgElement3.svg";


export const DefaultWrapper = styled.div`
    width: 100%;
    padding: 20px;
    margin-bottom: 50px;
`;

export const DefaultClientesWrapper = styled(DefaultWrapper)`
    background-image: url(${backgroundElements2}), url(${backgroundElements3});
    background-position: right, left;
    background-repeat: no-repeat;

    @media screen and (max-width: 768px){
        background-image: none;
    }
`;
export const ClientesWrapper = styled.div`
    margin: 0 auto;
    display: block;
    max-width: 1100px;
`;
export const ClientesContainer = styled.div`
    display: grid;
    max-width: 100%;
    padding-top: 70px;
    grid-template-columns: 1fr;
    margin: 0 auto;
    

   
    
`;
export const ClientesImages = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;


export const ClientesTexto = styled.div`
    display: flex;
    align-items: center;
    text-align: start;

    @media screen and (max-width: 500px){
        padding: 3px;
        text-align: center;
    }
`;

export const Subtitle = styled.p`
    font-size: 4rem;
    margin-top: 10px;
    font-family: "prelo-book";
    color: var(--sec-color);
`;

export const ImagesWrapper = styled.div`
    position: relative;
    align-self: center;
    background-color: #EAF5DE;
    padding-bottom: 50%;
    width: 50%;
    border-radius: 50%;

    @media screen and (max-width: 768px){
        padding-bottom: 70%;
        width: 70%;
    }
`;

export const ImageCircle = styled.div`
    position: absolute;
    top: 0px; left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;    
    border-radius: 50%;
    background-image: linear-gradient(#73A533, #D6DF23);
    box-shadow: 10px 10px rgba(0, 98, 90, 0.05),
                15px 15px rgba(0, 98, 90, 0.025);
    padding: 5px;
    animation-duration: 1s;
    animation-iteration-count: infinite;

    @media screen and (max-width: 500px){
        padding: 2px;
    }`;


export const ImageContainer = styled.div`
    padding: 10px;
    overflow: hidden;
    height: 95%;
    width: 95%;
    border-radius: 50%;
    background-color: white;

    @media screen and (max-width: 500px){
        padding: 3px;
    }
`;



export const Image = styled.img`
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    object-fit: contain;
    `


export const ClientesTitulo = styled.h1`
    display: inline-block;
    font-size: 2rem;
    position: relative;
    line-height: 2.5rem;
    letter-spacing: 1px;
    color: #C0E29D;
    font-family: "prelo-semibold";
    margin-left: 20px;
    text-transform: uppercase;

    &:before{
        content: "";
        position: absolute;
        content: "";
        width: 0.8rem;
        height: 0.8rem;
        background: #C0E29D;
        top: 0; bottom: 0;
        margin: auto 0;
        left: -20px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
    }
    
    &:after{
        content: "";
        position: absolute;
        content: "";
        width: 0.8rem;
        height: 0.8rem;
        background: #C0E29D;
        top: 0; bottom: 0;
        margin: auto 0;
        right: -20px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
    }
    `;

export const ClientesSubtitle = styled.h1`
    font-family: 'prelo-semibold';
    font-size: 5rem;
    line-height: 5.2rem;
    color: #283C11;
`;

