import styled from "styled-components"
import equipe from "../../assets/img/pedro.jpg"
import { DefaultWrapper, ClientesTitulo, ClientesSubtitle } from "../Clientes/ClientesElements"
import waves from "../../assets/svg/waves.svg"
import bgElement8 from "../../assets/svg/bgElement8.svg"

export const Wrapper = styled(DefaultWrapper)`
    padding: 0;
    margin-bottom: 0;
    background-color: var(--main-color);
    background-image: url(${bgElement8});
    background-position: right;
    background-repeat: no-repeat;
    overflow:hidden;
`;
export const EquipeWrapper = styled.section`
    margin: 0 auto;
    max-width: 1100px;
    width: 100%;
    padding: 0 30px;
`

export const EquipeTitle = styled(ClientesTitulo)`
`;

export const EquipeSubtitle = styled(ClientesSubtitle)`
    color: white;
`;

export const CarouselWrapper = styled.div`
    max-height: 600px;
    margin: auto;
    width: 90%;
`;


export const CardSide = styled.div`
    display: block;
    transition-duration: 0.5s;
    display: block;
    width: inherit;
    height: 250px;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    padding: 1rem;

    @media screen and (max-width: 850px){
        height: 200px
    }
`;

export const CardFront = styled(CardSide)`
    transform: rotateY(0deg);
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
`;
export const CardBack = styled(CardSide)`
    position: absolute;
    font-size: 5rem;
    opacity: 0;
    top: 0px;
    left: 0px;
    bottom: 20px;
    width: 100%;
    transform: rotateY(-180deg);
    background-color: var(--main-color);
`;

export const ProgressBar = styled.div`
    width:100%;
    height: 10px;
    background-color: #9FCE6F;
    border-radius: 20px;
    overflow:hidden;
`;

export const ProgressLevel = styled.div`
    display: block;
    height:100%;
    background-color: #C5FF89;
    width:${props => props.level ? props.level : "20%"};
`;


export const CardContainer = styled.div`
    position: relative;
    display: inline-block;
    width: 200px;
    margin: 20px 0;
    border: 3px solid #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
    

    @media screen and (max-width: 850px){
        width: 180px
    }

    &:after{
        position: absolute;
        background: #fff;
        z-index: -1;
        transition: 1s width;
        content: '';
    }
    
    &:before{
        position: absolute;
        background: #fff;
        z-index: -1;
        transition: 1s height;
        content: '';
    }

    &:before{
        background: var(--main-color);
        height: 100%;
        width: 105%;
    }

    &:after {
        background: var(--main-color);
        height: 105%;
        width: 100%;
    }


    &:hover{
        &:after{
            width: 0;
        }

        &:before{
            height: 0;
        }
        ${CardFront}{
            transform: rotateY(180deg);
            /* box-shadow: none; */
        }
        ${CardBack}{
            opacity: 1;
            transform: rotateY(0deg);
        }
    }
`;



export const Name = styled.h1`
    color: var(--sec-color);
    font-size: 1.6rem;
    font-family: 'prelo-semibold';
`

export const Funcao = styled.p`
    color: var(--sec-color);
    font-size: 1.5rem;
    font-family: 'prelo-book';
`

export const CardContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
`;


export const ProfileImageContainer = styled.div`
    height: 60%;
    width: 100%;
`;
export const ProfileImage = styled.img`
    height: 95%;
    margin: 0 auto;
    border-radius: 100%;
    background-color:green;
    align-self: center;
`;


export const ProfileNameWrapper = styled.div`
    height: 20%;
    margin-top: 1rem;
    text-align: center;
`;

export const ProfileName = styled.h1`
    font-family: 'prelo-semibold';
    margin: auto 0;
    display: inline-block;
    font-size: 20px;
    line-height: 98%;
    color: #959B8E;
`;

export const IconAndLevelWrapper = styled.div`
    padding: 20px;
    display: grid;
    grid-template-columns:  1fr 4fr;
    grid-gap: 7px;
    grid-template-rows:30px 30px 30px;
    justify-content: center;
    align-items: center;
`;



export const Icon = styled.svg`
    height: 100%;
    width: 100%;
`;

export const LinkedInIconWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;


export const LinkedInIcon = styled.svg`
    height: 50px;
    width: 50px;
    cursor: pointer;
    transition: transform 0.2s;
    &:hover{
        transform: scale(1.2);
    }
`;

export const ProfileJob = styled.h2`
    color: var(--main-color);
    font-family: 'prelo-semibold';
    font-size: 12px;
    letter-spacing: 0.2rem;
`;

export const EquipeDivider = styled.div`
    width: 100vw;
    height: 150px;
    background-image: url(${waves});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

export const EquipeDividerFlipped = styled(EquipeDivider)`
    transform: rotate(180deg);
`;