export const settings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
        {
            breakpoint: 424,
            settings: {
                dots: true,
                arrows: false,
                slidesToShow: 1,
            }
        },],
};