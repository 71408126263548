import axios from "axios";



const api = axios.create(
    {
        baseURL: "https://www.inovacda.com.br/api"
    }
);

export default api;