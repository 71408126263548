import React, { useState, useEffect } from 'react'
import api from "../../servives/api"
import { MidiaContainer, MidiaWrapper, Image, CarouselWrapper, CarouselItems, Textos, Titulo, NoticiasSecudariasWrapper, NoticiasSecudarias, DefaultMidiaWrapper } from "./MidiaElements"
import Carousel from "../Carousel/Carousel";
import { ClientesSubtitle, ClientesTitulo } from "../Clientes/ClientesElements"
import { settings } from "./CarouselSettings"


const Midia = () => {
    const [midia, setMidia] = useState([]);
    useEffect(() => {
        api
            .get("/midia")
            .then((response) => setMidia(response.data))
            .catch((err) => {
                console.log(err);
            });
    }, [])

    const createNoticias = (items) => {
        const noticiasSecudarias = items.map((item) => {
            return (
                <NoticiasSecudarias>
                    <Image src={item.foto} />
                    <Textos />
                </NoticiasSecudarias>
            );
        });
        return noticiasSecudarias;

    }

    const createNoticiasPrincipais = (items) => {
        const noticiasPrincipais = items.map((item) => {
            return (
                <CarouselItems>
                    <Image src={item.foto} />
                    <Textos>
                        <Titulo>
                            {item.titulo}
                        </Titulo>
                    </Textos>
                </CarouselItems>
            );
        });
        return noticiasPrincipais;
    }

    return (
        <DefaultMidiaWrapper id="noticias">
            <MidiaContainer>
                <ClientesTitulo>
                    Na Mídia
                </ClientesTitulo>
                <ClientesSubtitle>
                    O que estão falando sobre nós
                </ClientesSubtitle>
                <MidiaWrapper>
                    <CarouselWrapper>
                        <Carousel
                            propSettings={settings}
                            backgroundColor={'var(--main-color)'}
                        >
                            {
                                midia.length > 0 ? createNoticiasPrincipais(midia.slice(0, 2)) : []
                            }
                        </Carousel>
                    </CarouselWrapper>
                    <NoticiasSecudariasWrapper>
                        {
                            midia.length > 0 ? createNoticias(midia.slice(1, midia.length + 1)) : []
                        }
                    </NoticiasSecudariasWrapper>
                </MidiaWrapper>
            </MidiaContainer>
        </DefaultMidiaWrapper>
    )
}

export default Midia











