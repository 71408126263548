import styled from "styled-components"
import HomeSvg from "../../assets/svg/homeSvg.svg"

export const HomeContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${HomeSvg});
    background-position: bottom;
    background-size:  cover;
    background-repeat: no-repeat;
    overflow: hidden;
    padding: 20rem 5rem;
    padding-bottom: 25rem;
`;


export const HomeContent = styled.div`
    max-width: 1300px;
`;

export const HomeSubtitle = styled.div`
    color: #C0E29D;
    text-transform: uppercase;
    font-family: "prelo-extrabold";
    font-size: 3rem;
    margin-left: 10px;
    text-indent: 0px;
    line-height: 3rem;
`;

export const HomeTitle = styled.h1`
    color: #fff;
    text-transform: uppercase;
    font-family: "prelo-semibold";
    font-size: 8.5rem;
    letter-spacing: 0;
    line-height: 98.5%;

    p{
        font-family: "prelo-semibold";
        font-size: 8.5rem;
        letter-spacing: 0;
        line-height: 98.5%;
    }
`;

export const HomeButton = styled.button`
    display: inline-block;
    background-color: #fff;
    border-radius: 50px;
    margin-top: 3.2rem;
    margin-left: 1rem;
    padding: 1.5rem;
    border: none;
    `;

export const ButtonText = styled.h1`
    font-size: 3rem;
    margin: 0;
    font-family: "prelo-extrabold";
    line-height: 3rem;
    background: -webkit-linear-gradient(0deg,#588827, #CED41C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;
