import styled from "styled-components"
import { DefaultWrapper } from "../Clientes/ClientesElements";
import backgroundElements5 from "../../assets/svg/bgElement5.svg";


export const DefaultMidiaWrapper = styled(DefaultWrapper)`
    background-image: url(${backgroundElements5});
    background-position: right;
    background-repeat: no-repeat;
`

export const MidiaContainer = styled.div`
    display: block;
    margin: 0 auto;
    margin-left: auto;
    margin-right: auto;
    max-width: 1100px;
    min-width: 300px;
`;


export const MidiaWrapper = styled.div`
    margin-top: 40px;
    max-width: 95%;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    justify-content: center;
`;

export const CarouselWrapper = styled.div`
    margin: 0 auto;
    width: 100%;
`;

export const Image = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: 0.5s;
    `;

export const Textos = styled.div`
    flex-direction: column-reverse;
    position: absolute;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 00, 0.3);
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    z-index: 999;
    display: flex;
    transition: 0.5s;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    `;


export const Titulo = styled.h1`
    color: white;
    font-family: "prelo-semibold";
    text-align: center;
    font-size: 3.3rem;
`;

export const CarouselItems = styled.div`
    position: relative;
    display: block;
    min-height: 150px;
    max-height: 500px;
    height: 100%;
    border-radius: 10px;
    overflow:hidden;
    width: 95%;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;  
    aspect-ratio: auto 16 / 9; 

    &:hover{
        ${Image}{
            transform: scale(1.05);
        }
    }
`


export const NoticiasSecudariasWrapper = styled.div`
    display: grid;
    max-width: 95%;
    margin: 10px auto;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    
`;

export const NoticiasSecudarias = styled.div`
    position: relative;
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-height: 150px;
    border-radius: 10px;
    overflow: hidden;
    background-color: black;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    &:hover{
        ${Image}{
            transform: scale(1.1);
        }

        ${Textos}{
            display: flex;
        }
    }
`;