import { NavBar } from './components/NavBar/NavBar.js'
import { BrowserRouter } from 'react-router-dom';
import "./app.css"
import Clientes from './components/Clientes/Clientes.js';
import Midia from "./components/Midia/Midia.js"
import Equipe from "./components/Equipe/Equipe.js"
import React, { useState, useEffect } from 'react';
import Vitrine from "./components/Vitrine/Vitrine.js"
import Footer from "./components/Footer/Footer.js"
import Home from "./components/Home/Home.js"
function App() {

  // const [background, setBackground] = useState(false)
  // useEffect(() => {
  //   const scrollListener = () => {
  //     if (window.scrollY > 10) {
  //       setBackground(true)
  //     } else {
  //       setBackground(false);
  //     }
  //   };


  //   window.addEventListener('scroll', scrollListener)
  //   return () => {
  //     window.removeEventListener('scroll', scrollListener)
  //   }
  // }, []);



  return (
    <div id="landingPage">
      <BrowserRouter>
        <NavBar  />
      </BrowserRouter>

      <Home />
      <Vitrine />
      <Clientes />
      <Equipe />
      <Midia />
      {/* <Contato /> */}
      <Footer />
    </div>
  );
}

export default App;
