import styled from 'styled-components'
import { Link } from "react-scroll";

export const svgIcon = styled.img`
`;

const activeclassname = 'active'

export const NavLinks = styled(Link).attrs({ activeclassname })`
    color: var(--sec-color);
    position: relative;
    display: block;
    font-size: 2rem;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    padding: 0 1.5rem;
    font-family: 'prelo-bold';
    text-transform: uppercase;
    color: #fff;
    border-radius: 50px;
    margin: 0 5px;

    &:hover{
        color: #fff !important;
        background-color: #6BA530;
    }

    &.${activeclassname}{
        background-color: #6BA530 ;
        color:white !important;
    }


    @media screen and (max-width: 768px){
        font-size: 5rem;
        margin-top: 25px;
    }

`;

export const Nav = styled.nav`
    height: 50px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    position: fixed;
    z-index:1000;
    transition: top 0.3s, background-color 1s;
    background-color: ${({ background }) => (background ? '#FAFDF7' : "transparent")};
    top: ${({ topPosition }) => (topPosition ? '0' : "-100%")};

    ${NavLinks}{
        color: ${({ background }) => (background ? '#283C11' : "fff")};
    }
    
    @media (max-width: 768px){
        ${NavLinks}{
            color:#283C11
        }
    }
`;

export const NavContainer = styled.div`
    display:  flex;
    justify-content: space-between;
    height: 50px;
    z-index: 1;
    width: 100vw;
    padding: 0 24px;
    max-width: 1300px;
`;

export const NavLogo = styled.div`
    display: flex;
    justify-items: center;
    align-items: center;
    cursor: pointer;
`;


export const MobileIcon = styled.div`
    display: none;
    @media screen and (max-width: 768px){
        display: block;
        font-size: 3rem;
        align-self: center;
        cursor: pointer;
        color: #283C11
    }
`;

export const AnimatedIcon = styled.span`
    background: #fff;
    border-radius:10px;
    height:7px;
    margin: 7px 0;
`;

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    justify-self: center;
    height: 100%;
    overflow: hidden;

    @media screen and (max-width: 768px){
        position: fixed;
        background-color: #FAFDF7;
        top: 50px;
        opacity :${({ isOpen }) => (isOpen ? '0' : "1")};
        height: ${({ isOpen }) => (isOpen ? '0px' : "100%")};
        width: 100vw;
        right: 0;
        margin-top: -10px;
        flex-direction: column;
        justify-content: center;
        transition: 0.3s ease-in;
    }
`;


