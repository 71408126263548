import styled from "styled-components"
import footerDivider from "../../assets/svg/footerDivider.svg";
import { DefaultWrapper } from "../Clientes/ClientesElements"
import { Link } from "react-scroll";


export const FooterWrapper = styled(DefaultWrapper)`
    overflow: hidden;
    padding: 0;
    margin:0;
    background: #6BA530;
`;

export const FooterContainer = styled.div`
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const LogoContainer = styled.div`
display:flex;
justify-content: center;
`;

export const LogoWrapper = styled.small`
    margin: 20px 10px;
    display: grid;
    place-items: center;
    flex-direction: row;
`;
export const LinksContainer = styled.div`
    margin-top: 10px;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    overflow: hidden;

    @media (max-width: 500px){
        display: flex;
        flex-direction: column;
    }
`;

export const Links = styled(Link)`
    color: var(--sec-color);
    position: relative;
    display: block;
    font-size: 2rem;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    padding: 0 1.5rem;
    font-family: 'prelo-semibold';
    color: #fff;
    border-radius: 50px;
    margin: 0 5px;
`;

export const MidiaAndContactContainer = styled.div`
    display:flex;
    margin: 0 auto;
    max-width: 1100px;
    width: 100%;
    justify-content: space-evenly; 
`;
export const ContactContainer = styled.div`
display: flex;
align-items: center;
color: #fff;
`;

export const ContactText = styled.p`
        color: #fff;
        margin-left: 0.5rem;
        font-size: 1.7rem;
        text-align: center;
        font-family: 'prelo-semibold';
`;
export const FooterRights = styled.div`
display: flex;
background-color: #4D7722;
color: white;
`;